
.basic_label{
    font-family: "Roboto";
  }
  
  .basic_input{
    font-family: "Roboto";
    width: 100%;
    padding: 15px;
    margin: 5px 0 22px 0;
    display: inline-block;
    border: none;
    background: #f1f1f1;
  }
  
  .basic_input:focus{
    background-color: #ddd;
    outline: none;
  }
  
  .buttonCancel      {
      padding: 14px 20px;
      border:1px solid #424949;
      border-radius: 4px;
      background-color: white;
      color:#424949;
      cursor: pointer;
  
      float: left;
      font-family: "Roboto";
      font-size: 18px;
      line-height: 1.428571429;
      margin: 4px;
  
      width: 47%;    
  }
  
  .buttonDel      {
    padding: 7px 10px;
    border:1px solid #F8F9F9;
    border-radius: 4px;
    background-color: #a80512;
    color:#F8F9F9;
    cursor: pointer;
  
    float: left;
    font-family: "Roboto";
    font-size: 14px;
    line-height: 1.2;
    margin: 2px; 
  }
  
  .buttonContact{
    padding: 7px 10px;
    border:1px solid #337ab7;
    border-radius: 4px;
    background-color: #F8F9F9;
    color:#337ab7;
    cursor: pointer;
    font-family: "Roboto";
    font-size: 17px;
    line-height: 1.6;
    margin: 2px; 
  }
  
  .buttonDetails     {
    padding: 7px 10px;
    border:1px solid #F8F9F9;
    border-radius: 4px;
    background-color: #337ab7;
    color:#F8F9F9;
    cursor: pointer;
    font-family: "Roboto";
    font-size: 14px;
    line-height: 1.2;
    margin: 2px; 
  }
  
  .buttonLink{
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: #337ab7;
    text-decoration: none;
  }
  
  .buttonOk      {
    padding: 14px 20px;
    border:1px solid #F8F9F9;
    border-radius: 4px;
    background-color: #424949;
    color:#F8F9F9;
    cursor: pointer;
  
    float: left;
    font-family: "Roboto";
    font-size: 18px;
    line-height: 1.428571429;
    margin: 4px;
  
    width: 47%;    
  }
  
  @media screen and (max-width: 300px) {
      .buttonCancel .buttonOk{
          width: 94%;    
      }
  }
  
  .divContent{
    background-color: white;
    margin:0 auto;
    max-width: 600px;
    min-width: 300px;
    width: 80%;
  }

  .divContentLarge{
    background-color: white;
    margin:0 auto;
    min-width: 300px;
    width: 80%;    
  }
  
        /* Set a style for all buttons */
        .button_signup {
          background-color: #424949;
          border-radius: 4px;
          color: white;
          font-family: "Roboto";
          font-size: 18px;
          line-height: 1.428571429;
          padding: 14px 20px;
          margin-left: 8px;
          margin-right: 8px;
          border: none;
          cursor: pointer;
          width: 80%;
          opacity: 0.9;
        }
  
        .button_signup:hover {
          opacity:1;
        }
  
        /* Extra styles for the cancel button */
        .cancelbtn_signup {
          padding: 14px 20px;
          border:1px solid #424949;
          background-color: white;
          color:#424949;
        }
  
        /* Float cancel and signup buttons and add an equal width */
        .cancelbtn_signup, .signupbtn_signup {
          float: left;
          width: 47%;
        }
  
        /* Add padding to container elements */
        .container_signup {
          padding: 16px;
        }
  
  
  
        /* The Modal (background) */
        .modal_signup {
          position: relative; /* Stay in place */
          z-index: -1; /* Sit on top */
          left: 0;
          top: 0;
          width: 100%; /* Full width */
          height: 100%; /* Full height */
          padding-top: 50px;
        }
  
        /* Modal Content/Box */
        .modal-content_signup {
          background-color: #fefefe;
          margin: 5% auto 15% auto; /* 5% from the top, 15% from the bottom and centered */
          border: 1px solid #888;
          width: 80%; /* Could be more or less, depending on screen size */
        }
  
        /* Style the horizontal ruler */
        .hr_signup {
          border: 1px solid #f1f1f1;
          margin-bottom: 25px;
        }
        
        /* The Close Button (x) */
        .close_signup {
          position: absolute;
          right: 35px;
          top: 15px;
          font-family: "Roboto";
          font-size: 40px;
          font-weight: bold;
          color: #f1f1f1;
        }
  
        .close_signup:hover,
        .close_signup:focus {
          color: #f44336;
          cursor: pointer;
        }
  
        /* Clear floats */
        .clearfix_signup{
          align-items: center;
        }
  
        .clearfix_signup::after {
          content: "";
          clear: both;
          display: table;
        }
  
        /* Change styles for cancel button and signup button on extra small screens */
        @media screen and (max-width: 300px) {
          .cancelbtn_signup, .signupbtn_signup {
            width: 90%;
          }
        }
  
        body.active-modal{
          overflow-y: hidden;
        }
  
        .btn-modal{
          padding: 10px 20px;
          display: block;
          margin: 100px auto 0;
          font-size: 18px;
        }
  
        .modal, .overlay{
          width: 100vw;
          height: 100vh;
          position: fixed;
          bottom: 0;
          left: 0;
          right: 0;
          top: 0;
  
        }
  
        .modal-content{
          position: absoulte;
          top: 40%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
  
        .overlay{
          background: rgba(49, 49, 49, 0.8);
        }

.imgMainNews{
  height: auto;
  width: 100%;
}     

.imgMainNewsCarrousel{
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 150px;
  max-width: 100%;
}

.videoMainNews{
  height: 400px;
  width: 400px;
}

.videoMainHomeCarrousel{
  display: block;
  height: 180px;
  max-width: 100%;
}

.videoMainNewsCarrousel{
  height: 200px;
  max-width: 100%;
}

 
  /* Style the tab */
  .tab {
    overflow: hidden;
    border: 1px solid #ccc;
    background-color: #f1f1f1;
  }
  
  /* Style the buttons inside the tab */
  .tabButton {
    background-color: inherit;
    float: left;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 14px 16px;
    transition: 0.3s;
    font-size: 17px;
  }
  
  .tabButtonActive{
    background-color: #ccc;
    float: left;
    font-weight: 500;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 14px 16px;
    transition: 0.3s;
    font-size: 17px;
  }
  
  /* Change background color of buttons on hover */
  .tabButton:hover {
    background-color: #ddd;
  }
  
  /* Create an active/current tablink class */
  .tab button.active {
    background-color: #ccc;
  }
  
  /* Style the tab content */
  .tabContent {
    border-top: none;
    height: 0px;
    visibility: hidden;
  }      
  
  .tabContentActive {
    padding: 6px 12px;
    border: 1px solid #ccc;
    border-top: none;
    height: auto;
    visibility: visible;
  }     
  
  .tableHeader{
    background-color: #f1f1f1;
    border: 1px solid #ccc;
    font-weight: 500;
    padding: 8px 8px;
    font-size: 14px;
  }
  
  .tableContent{
    background-color: white;
    border: 1px solid #ccc;
    font-weight: normal;
    padding: 8px 8px;
    font-size: 14px;
  }

  .divContainerL1{
    margin: 40px 0 80px;
    display: block;
  }

  .divContainerL2{
    width: 100%;
    box-sizing: border-box;
    display: block;
  }

  .divContainerL3{
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    box-sizing: border-box;
  }

  .divContainerL4{
    text-align: center!important;
    flex: 0 0 33%;
    max-width: 33%;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    box-sizing: border-box;
    display: block;
  }  

  .divContainerL5{
    box-shadow: 2px 2px 18px rgba(0,0,0,.1);
    background: #fff;
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 20px;
    box-sizing: border-box;
    display: block;
    text-align: center!important;
    min-width: 200px;
  }    

  .divContainerMainImage{
    display: block;
    width: 100%;
  }

  .divContainerMainText{
    height: 180px;
    width: 98%;
  }

  .divNewsContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 12px;
  }

  .divNewsMainImage{
    width: 37.6%;
  }
  .divNewsMainContent{
    width: 56.4%;
  }  

  @media (max-width: 768px) {
    .divNewsContainer {
      flex-direction: column;
    }
  
    .divNewsMainImage {
      width: 94%;
    }
  
    .divNewsMainContent {
      width: 94%;
      margin-top: 20px;
    }
  }

